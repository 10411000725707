import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const AboutSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqData = [
    {
      question: "What is NOSTAi?",
      answer: "NOSTAi is an AI-powered design service that creates personalized memory quilt layouts using your t-shirts and cherished clothing. It transforms sentimental items into visually stunning quilt designs, preserving your memories in a unique and creative way."
    },
    {
      question: "How does NOSTAi work?",
      answer: (
        <div>
          Here's how it works:
          <ul className="list-disc pl-6 mt-2">
            <li className="mb-2">Upload your t-shirt images: Provide clear pictures of your t-shirts or clothing items.</li>
            <li className="mb-2">AI analysis: Our AI extracts key features like colors, logos, and patterns from your images.</li>
            <li className="mb-2">Customized layouts: NOSTAi generates 3-5 quilt layout suggestions, optimized based on the number of items and your preferences.</li>
            <li>Choose your design: Select the layout you love, and we'll guide you on how to proceed with making your quilt.</li>
          </ul>
        </div>
      )
    },
    {
      question: "How many t-shirts or pieces of clothing do I need?",
      answer: (
        <div>
          The number of items depends on the quilt size and the size of the tiles you choose:
          <div className="mt-2">
            <p className="font-semibold">For Larger Tiles (11–12 inches):</p>
            <ul className="list-disc pl-6 mt-1 mb-2">
              <li>Twin Quilt (4' x 6'): 4x6 matrix – 24 t-shirt sides</li>
              <li>Full Quilt (5' x 7'): 5x7 matrix – 35 t-shirt sides</li>
              <li>Large Throw Quilt (7' x 8'): 7x8 matrix – 56 t-shirt sides</li>
            </ul>
            <p className="font-semibold">For Smaller Tiles (9–10 inches):</p>
            <ul className="list-disc pl-6 mt-1">
              <li>Twin Quilt (4' x 6'): 5x7 matrix – 35 t-shirt sides</li>
              <li>Full Quilt (5' x 7'): 6x8 matrix – 48 t-shirt sides</li>
              <li>Large Throw Quilt (7' x 8'): 8x9 matrix – 72 t-shirt sides</li>
            </ul>
          </div>
          <p className="mt-2">Each t-shirt typically has two usable sides (front and back), giving you flexibility to design your quilt creatively.</p>
        </div>
      )
    },
    {
      question: "Do I receive the finished quilt from NOSTAi?",
      answer: "No, NOSTAi specializes in designing your quilt layout. After choosing your design, you can partner with a quilt maker, like Sweet Root Memory Quilter, to bring your quilt to life. We'll guide you on the next steps to ensure a seamless process."
    },
    {
      question: "Why should I use NOSTAi instead of designing on my own?",
      answer: (
        <div>
          NOSTAi simplifies the design process with:
          <ul className="list-disc pl-6 mt-2">
            <li>Professional and balanced quilt layouts</li>
            <li>Creative arrangement of colors, patterns, and motifs</li>
            <li>Multiple design options to suit your style</li>
            <li>A time-saving and stress-free experience</li>
          </ul>
        </div>
      )
    },
    {
      question: "Can I customize the layout further?",
      answer: "Yes! NOSTAi allows you to customize or edit the design to suit your preferences."
    },
    {
      question: "Is NOSTAi available globally?",
      answer: "Yes, NOSTAi is a global service. You can access it from anywhere in the world to create designs for your memory quilt."
    }
  ];

  const toggleQuestion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="flex flex-col justify-center w-full max-w-[994px] px-5 mx-auto">
      {/* About Section */}
      <h2 className="text-2xl tracking-wider leading-10 text-black text-center mb-5 max-md:text-lg" style={{ fontFamily: "Playfair Display" }}>
        What is NOSTAi for?
      </h2>
      <p className="text-lg tracking-wide leading-8 text-black text-center mb-16 max-md:text-base" style={{ fontFamily: "Playfair Display" }}>
        NOSTAi is for those who value memories. <br />
        It's for those who want to preserve their stories with the touch of technology. <br />
        For people who believe that the best memories deserve to be crafted with care. <br />
        They know, a thoughtful design brings more than nostalgia—it creates lasting connections.
      </p>

      {/* FAQ Section */}
      <h2 className="text-2xl tracking-wider leading-10 text-black text-center mb-8 max-md:text-lg" style={{ fontFamily: "Playfair Display" }}>
        Frequently Asked Questions
      </h2>
      <div className="space-y-4 mb-16">
        {faqData.map((faq, index) => (
          <div
            key={index}
            className="border border-gray-200 rounded-lg overflow-hidden"
          >
            <button
              className="w-full px-6 py-4 text-left flex justify-between items-center bg-white hover:bg-gray-50 transition-colors"
              onClick={() => toggleQuestion(index)}
            >
              <span className="text-lg font-medium" style={{ fontFamily: "Playfair Display" }}>
                {faq.question}
              </span>
              {openIndex === index ? (
                <ChevronUp className="w-5 h-5 text-gray-500" />
              ) : (
                <ChevronDown className="w-5 h-5 text-gray-500" />
              )}
            </button>
            <div
              className={`px-6 overflow-hidden transition-all duration-300 ease-in-out ${
                openIndex === index ? 'py-4' : 'max-h-0'
              }`}
            >
              <div className="text-gray-600 leading-relaxed" style={{ fontFamily: "Playfair Display" }}>
                {faq.answer}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default AboutSection;